<template>
	<div v-loading="fullscreenLoading">
		<!--收货地址-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">收货地址</div>

			<div clsas="address-list">

				<div
					class="address-item"
					v-for="(item, key) in memberAddress"
					:key="item.id"
					:class="addressId == item.id ? 'active' : ''"
					v-if="key < 3 || (addressShow && key >= 3)"
				>
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">编辑</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm title="确定要删除该地址吗？" @onConfirm="deleteMemberAddress(item.id)"><div slot="reference">删除</div></el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						添加收货地址
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					更多收货地址
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--购买虚拟类商品需填写您的手机号-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">购买虚拟类商品需填写您的手机号，以方便商家与您联系</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="手机号码"><el-input placeholder="请输入您的手机号码" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input></el-form-item>
			</el-form>
		</div>

		<!--收货地址添加-->
		<el-dialog :title="addressForm.id == 0 ? '添加收货地址' : '编辑收货地址'" :visible.sync="dialogVisible" width="50%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item label="姓名" prop="name"><el-input v-model="addressForm.name" placeholder="收货人姓名"></el-input></el-form-item>

				<el-form-item label="手机" prop="mobile"><el-input v-model="addressForm.mobile" maxlength="11" placeholder="收货人手机号"></el-input></el-form-item>

				<el-form-item label="电话"><el-input v-model="addressForm.telephone" placeholder="收货人固定电话（选填）"></el-input></el-form-item>

				<el-form-item class="area" label="地区" prop="area">
					<span slot="label"><font color="red">*</font>地区</span >
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" placeholder="请选择省">
								<el-option label="请选择省" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" placeholder="请选择市">
								<el-option label="请选择市" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" placeholder="请选择区/县">
								<el-option label="请选择区/县" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item label="详细地址" prop="address"><el-input v-model="addressForm.address" placeholder="定位小区、街道、写字楼"></el-input></el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">确 定</el-button>
			</span>
		</el-dialog>
		<!--支付方式 -->
		<div class="item-block" v-if="orderPaymentData.is_gift == 0">
			<div class="block-text">支付方式</div>
			<!--//支付方式：先货后款，在线支付，在线支付跳转到在线支付里面去 -->
			<!--<div class="pay-type-list">
				<input type="hidden" name="is_xhpay" :value="xhpay.is_xhpay" />
				<div class="pay-type-item" :class="xhpay.is_xhpay==1 ? 'active' : ''" @click="handlePayment(1)">先货后款</div>&nbsp;&nbsp;&nbsp;&nbsp;
				<div class="pay-type-item"  :class="xhpay.is_xhpay==2 ? 'active' : ''" @click="handlePayment(2)">在线付款</div>
			</div>-->
			<!--//支付方式：线下支付，要调到传对公转账的凭证 -->
			<div class="pay-type-list">
				<input type="hidden" name="pay_type" :value="pay_type.pay_type" />
				<input type="hidden" name="pay_type_name " :value="pay_type.pay_type" />
				<div class="pay-type-item" :class="pay_type.pay_type != '' ? 'active' : ''" @click="handlePaymentType('OFFLINE_PAY ')">线下付款</div>&nbsp;&nbsp;&nbsp;&nbsp;
				<div class="pay-type-item"  :class="pay_type.pay_type == '' ? 'active' : ''" @click="handlePaymentType('')">在线付款</div>
			</div>
		</div>
		<!--使用余额-->
		<!-- <div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && xhpay.is_xhpay == 0 && pay_type.pay_type == '' "> -->
			<!-- <div class="block-text">是否使用余额 <span class="member-account">【剩余余额：<b>{{orderPaymentData.member_account.balance_total}}</b></span>】</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">不使用余额</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">使用余额</div>
				<div class="clear"></div>
			</div> -->
		<!-- </div> -->
		<!--是否同步832 -->
		<div class="item-block" v-if="memberInfo.rank_type == 2 && false">
			<div class="block-text"><el-checkbox @change="checkIsBudget" v-model="isBudgetForm.isChecked">是否纳入考核同步</el-checkbox></div>
		</div>
		<!--商品信息-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="50%">商品</td>
						<td width="12.5%">价格</td>
						<td width="12.5%">数量</td>
						<td width="12.5%">小计</td>
					</tr>
				</table>
			</div>
		</div>
		<div v-for="(siteItem, siteIndex) in orderPaymentData.shop_goods_list" :key="siteIndex">
			<!-- <div class="item-block">
				<div class="goods-list">
					<table>
						<tr>
							<td colspan="5">
								<i class="store-icon iconfont iconmendian"></i>
								<router-link :to="'/shop-' + siteItem.site_id" target="_blank">
									<span class="p_tag_832" v-if="siteItem.tag_type == 1">832产品</span>
									<span class="p_tag_832" v-if="siteItem.tag_level == 2">省贫认定</span>
									<span class="p_tag_832" v-if="siteItem.tag_level == 3">市贫认定</span>
									<span class="p_tag_832" v-if="siteItem.tag_level == 4">县贫认定</span>
									{{ siteItem.site_name }}</router-link>
							</td>
						</tr>
					</table>
				</div>
			</div> -->
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in siteItem.goods_list" :key="goodsIndex">
							<td width="50%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'small' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">
												<span class="p_tag_832" v-if="goodsItem.tag_type == 1">832产品</span>
												<span class="p_tag_832" v-if="goodsItem.tag_level == 2">省贫认定</span>
												<span class="p_tag_832" v-if="goodsItem.tag_level == 3">市贫认定</span>
												<span class="p_tag_832" v-if="goodsItem.tag_level == 4">县贫认定</span>
												{{ goodsItem.sku_name }}</div>
										</router-link>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-price">￥{{ goodsItem.discount_price }}</td>
							<td width="12.5%" class="goods-num">{{ goodsItem.num }}</td>
							<td width="12.5%" v-if="goodsIndex == 0" :rowspan="siteItem.goods_list.length" class="goods-money">￥{{ siteItem.pay_money | moneyFormat }}</td>
						</tr>
					</table>

					<!--配送方式、留言、优惠券、店铺优惠-->
					<div class="goods-footer">
						<div class="goods-footer-left">
							<div v-if="orderPaymentData.is_virtual == 0">
								<div class="order-cell">
									<div class="tit">
										配送方式
										<span class="ns-text-color">
											<span
												v-if="
													orderPaymentData.delivery[siteItem.site_id].delivery_type == 'store' && orderPaymentData.delivery[siteItem.site_id].store_name
												"
											>
												{{ orderPaymentData.delivery[siteItem.site_id].store_name }}
											</span>
										</span>
									</div>
									<div v-if="siteItem.express_type.length > 0">
										<div
											class="express-item"
											v-for="(deliveryItem, deliveryIndex) in siteItem.express_type"
											:key="deliveryIndex"
											@click="selectDeliveryType(deliveryItem, siteItem.site_id, siteItem.express_type)"
											:class="orderPaymentData.delivery[siteItem.site_id].delivery_type == deliveryItem.name ? 'active' : ''"
											v-if="deliveryItem.name != 'local'"
										>
											{{ deliveryItem.title }}
										</div>
									</div>
									<div v-else-if="memberAddress.length == 0"><div class="box ns-text-color"  @click="addAddressShow">请先添加收货地址</div></div>
									<div v-else><div class="box ns-text-color">商家未配置配送方式</div></div>
								</div>
							</div>

							<div v-if="siteItem.coupon_list.length > 0">
								<div class="order-cell">
									<div class="tit">优惠券</div>
									<div v-if="siteItem.coupon_id">
										<span class="ns-text-color" @click="openSiteCoupon(siteItem.site_id, siteItem.coupon_list)">已选择优惠券，已优惠</span>
										<span class="ns-text-color" @click="openSiteCoupon(siteItem.site_id, siteItem.coupon_list)">
											<span class="inline">￥</span>
											{{ siteItem.coupon_money }}
										</span>
									</div>

									<div v-else><div class="box ns-text-color" @click="openSiteCoupon(siteItem.site_id, siteItem.coupon_list)">不使用优惠券</div></div>
								</div>
							</div>

							<div>
								<div class="order-cell" v-if="siteItem.promotion_money > 0">
									<span class="tit">店铺优惠</span>
									<div class="box text-overflow">
										<div class="order-cell" v-for="(promotionItem, promotionIndex) in siteItem.promotion" :key="promotionIndex">
											<div class="box text-overflow" v-if="promotionIndex == 'manjian'">
												<span class="ns-text-color">满减：</span>
												<span class="ns-text-color" v-for="(item, index) in promotionItem" :key="index">{{ item.discount_array.desc }}</span>
											</div>

											<div class="box text-overflow" v-if="promotionIndex == 'freeshipping'">
												<span class="ns-text-color">满额包邮：</span>
												<span class="ns-text-color">{{ promotionItem.area_names }} 满{{ promotionItem.price }}包邮</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="goods-footer-right">
							<div>
								<div class="order-cell">
									<div class="tit">买家留言</div>
									<div class="box">
										<el-input
											rows="3"
											type="textarea"
											placeholder="留言前建议先与商家协调一致"
											v-model="orderCreateData.buyer_message[siteItem.site_id]"
											class="buyer-message"
											@input="textarea"
											maxlength="140"
											show-word-limit
											resize="none"
										></el-input>
									</div>
								</div>
							</div>
						</div>
						<div class="clear"></div>
					</div>
				</div>
			</div>
		</div>

		<!--平台优惠券-->
		<div class="item-block" v-if="orderPaymentData && orderPaymentData.platform_coupon_list && orderPaymentData.platform_coupon_list.length > 0 && orderPaymentData.is_gift == 0">
			<div class="block-text">平台优惠券</div>

			<div class="order-cell platform-coupon">
				<div v-if="orderCreateData.platform_coupon_id">
					<span class="ns-text-color" @click="openPlatformCoupon">已选择优惠券，已优惠</span>
					<span class="ns-text-color" @click="openPlatformCoupon">
						<span class="inline">￥</span>
						{{ orderPaymentData.platform_coupon_money }}
					</span>
				</div>

				<div v-else><div class="box ns-text-color" @click="openPlatformCoupon">不使用优惠券</div></div>
			</div>
		</div>

		<div class="item-block" v-if="orderPaymentData && orderPaymentData.balance_type && orderPaymentData.balance_type.length > 0 && orderPaymentData.is_gift == 0">
			<div class="block-text">消费券</div>

			<div class="order-cell platform-coupon">
				<div v-if="orderCreateData.balance_type_id">
					<span class="ns-text-color" @click="openVoucherType">已选择消费券</span>
					<span class="ns-text-color" @click="openVoucherType">
						{{ orderCreateData.balance_type_name }}抵扣
						<span class="inline">￥</span>
						{{ orderCreateData.balance_type_money }}
					</span>
				</div>
				<div v-else><div class="box ns-text-color" @click="openVoucherType">不使用消费券</div></div>
			</div>
		</div>

		<!-- 总计 -->
		<div class="item-block"  v-if="orderPaymentData.is_gift == 0">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">商品金额：</td>
						<td align="left">￥{{ orderPaymentData.goods_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">运费：</td>
						<td align="left">￥{{ orderPaymentData.delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">税费：</td>
						<td align="left">￥{{ orderPaymentData.invoice_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">优惠：</td>
						<td align="left">￥{{ orderPaymentData.promotion_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.coupon_money > 0">
						<td align="right">店铺优惠券：</td>
						<td align="left">￥{{ orderPaymentData.coupon_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.platform_coupon_money > 0">
						<td align="right">平台优惠券：</td>
						<td align="left">￥{{ orderPaymentData.platform_coupon_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">使用余额：</td>
						<td align="left">￥{{ orderPaymentData.balance_money | moneyFormat }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<div class="item-block" v-if="orderPaymentData.is_gift == 1">
			<div class="block-text">请输入提货券上的券码和兑换码</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="券码"><el-input placeholder="请输入券码" maxlength="20" v-model="orderCreateData.card_no"></el-input></el-form-item>
			</el-form>
			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="兑换码"><el-input placeholder="请输入兑换码" maxlength="50" v-model="orderCreateData.card_code"></el-input></el-form-item>
			</el-form>

		</div>

		<!--结算-->
		<div class="item-block" v-if="orderPaymentData.is_gift == 0">
			<div class="order-submit">
				<div v-if="orderCreateData.is_balance">
					<div class="order-money">
						共{{ orderPaymentData.goods_num }}件，使用余额：
						<div class="ns-text-color">￥{{ orderPaymentData.balance_money | moneyFormat }}</div>
					</div>
					<el-button type="primary" class="el-button--primary" @click="openPlatformBalance">订单余额结算</el-button>
				</div>
				<div v-else>
					<div class="order-money">
						共{{ orderPaymentData.goods_num }}件，应付金额：
						<div class="ns-text-color">￥{{ orderPaymentData.pay_money | moneyFormat }}</div>
					</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">订单结算</el-button>
				</div>
			</div>
			<div class="clear"></div>
		</div>
		<div class="item-block" v-else>
			<div class="order-submit">
				<div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">兑换</el-button>
				</div>
			</div>
			<div class="clear"></div>
		</div>

		<!--余额结算确认支付弹框-->
		<el-dialog title="确认余额支付" :visible.sync="dialogBalance" width="25%" customClass="customBalanceWidth">
			<div class="order-statistics-show">
				<table class="table-balance">
					<tr>
						<td align="right">剩余余额：</td>
						<td align="left"><b>{{orderPaymentData.member_account.balance_total}}</b></td>
					</tr>
					<tr>
						<td align="right">商品金额：</td>
						<td align="left">￥{{ orderPaymentData.goods_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">运费：</td>
						<td align="left">￥{{ orderPaymentData.delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">税费：</td>
						<td align="left">￥{{ orderPaymentData.invoice_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">优惠：</td>
						<td align="left">￥{{ orderPaymentData.promotion_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.coupon_money > 0">
						<td align="right">店铺优惠券：</td>
						<td align="left">￥{{ orderPaymentData.coupon_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.platform_coupon_money > 0">
						<td align="right">平台优惠券：</td>
						<td align="left">￥{{ orderPaymentData.platform_coupon_money | moneyFormat }}</td>
					</tr>
					<tr class="balance-money" v-if="orderPaymentData.balance_money > 0">
						<td align="right">使用余额：</td>
						<td align="left">￥{{ orderPaymentData.balance_money | moneyFormat }}</td>
					</tr>
					<tr>
						<td colspan="2" class="table-but">
							<el-button @click="dialogBalance = false">取消</el-button>
							<el-button @click="orderCreate" type="primary">确认支付</el-button>
						</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</el-dialog>

		<!--选择优惠券弹框-->
		<el-dialog title="选择优惠券" :visible.sync="dialogCoupon" width="50%">
			<el-table ref="couponTable" :data="siteCoupon.data" highlight-current-row @row-click="selectCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="couponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coupon_name" label="名称" width="200"></el-table-column>
				<el-table-column label="优惠">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 'reward'">{{ scope.row.money }}</span>
						<span v-if="scope.row.type == 'discount'">{{ scope.row.discount }} 折</span>
					</template>
				</el-table-column>
				<el-table-column prop="use" label="使用"></el-table-column>
				<el-table-column prop="time" label="期限"></el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogCoupon = false">取消</el-button>
				<el-button @click="saveCoupon()" type="primary">确认选择</el-button>
			</div>
		</el-dialog>

		<!--选择平台优惠券弹框-->
		<el-dialog title="选择平台优惠券" :visible.sync="dialogPlatcoupon" width="50%" @close="savePlatformCoupon()">
			<el-table ref="platformCouponTable" :data="orderPaymentData.platform_coupon_list" highlight-current-row @row-click="selectPlatformCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="platformCouponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="platformcoupon_name" label="名称" width="200"></el-table-column>
				<el-table-column prop="money" label="优惠"></el-table-column>
				<el-table-column label="使用">
					<template slot-scope="scope">
						<span class="ns-text-color-gray ns-font-size-sm" v-if="scope.row.at_least > 0">满{{ scope.row.at_least }}可用</span>
						<span class="ns-text-color-gray ns-font-size-sm" v-else>任意金额可用</span>
					</template>
				</el-table-column>
				<el-table-column label="期限">
					<template slot-scope="scope">
						<span>{{ $util.timeStampTurnTime(scope.row.end_time) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogPlatcoupon = false">取消</el-button>
				<el-button @click="dialogPlatcoupon = false" type="primary">确认选择</el-button>
			</div>
		</el-dialog>

		<!--选择平台消费券弹框-->
		<el-dialog title="选择平台消费券" :visible.sync="dialogVoutherType" width="50%" @close="saveVoutherType()">
			<el-table ref="platformCouponTable" :data="orderPaymentData.balance_type" highlight-current-row @row-click="selectVoutherType" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="voucherTypeRadio" :label="scope.row.type_id"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="type_name" label="名称" width="200"></el-table-column>
				<el-table-column prop="balance" label="余额"></el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span class="ns-text-color-gray ns-font-size-sm">{{ scope.row.usable?"可用":"不可用" }}</span>
					</template>
				</el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogVoutherType = false">取消</el-button>
				<el-button @click="dialogVoutherType = false" type="primary">确认选择</el-button>
			</div>
		</el-dialog>

		<!--配送方式  门店 -->
		<el-dialog title="选择门店" :visible.sync="dialogStore" width="50%">
			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="名称" width="160"></el-table-column>
				<el-table-column prop="store_address" label="地址"></el-table-column>
				<el-table-column prop="open_date" label="营业时间">
					 <template slot-scope="scope">{{ scope.row.open_date || '未提供' }}</template>
				</el-table-column>
			</el-table>
		</el-dialog>

		<!-- 配送方式 外卖 -->
		<el-dialog title="送达时间" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="送达时间">
					<el-col :span="11">
						<el-time-picker
							format="HH:mm"
							value-format="HH:mm"
							placeholder="选择时间"
							:value="time"
							v-model="time"
							start="09:01"
							end="21:01"
							@change="bindTimeChange"
						></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">关闭</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">确认选择</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 支付密码 -->
		<el-dialog title="使用余额" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>为了您的账户安全,请您先设置的支付密码</p>
				<p>可到"会员中心","账号安全","支付密码"中设置</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">暂不设置</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">立即设置</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="支付密码" class="pay-password-item">
					<!--添加一个不可见的input,欺骗浏览器自动填充-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">忘记密码</p>
			</el-form>
		</el-dialog>

		<!-- 832账号授权 -->
		<el-dialog title="预算单位授权验证" :visible.sync="verifyIsBudget" width="450px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<el-form :model="isBudgetForm" ref="isBudgetRuleForm" label-width="70px">
				<el-form-item prop="account" label="账号" >
					<el-input v-model.trim="isBudgetForm.account" placeholder="请输入预算单位账号"></el-input>
				</el-form-item>
				<el-form-item prop="yspassword" label="密码" >
					<el-input type="password" v-model.trim="isBudgetForm.yspassword" autocomplete="off" placeholder="请输入登录密码"></el-input>
				</el-form-item>
				<el-form-item prop="vercode" label="验证码" >
					<el-input v-model.trim="isBudgetForm.vercode" autocomplete="off" placeholder="请输入验证码" maxlength="4">
						<template slot="append" style="min-width: 100px">
							<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
						</template>
					</el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-button type="primary"  @click="accountLogin" >授权</el-button>
					<el-button type="default"  @click="closeDialog" >取消</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 832同步订单提示 -->
		<el-dialog title="提示" :visible.sync="synIsFupin" width="580px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<div class="isfupin-box">
				<p>您好！</p>
				<p>
					<span>您本次订单的商品总金额为：</span>
					<span class="title_1" v-if="orderCreateData.is_balance">{{ orderPaymentData.balance_money | moneyFormat }}元</span>
					<span class="title_1" v-else>{{ orderPaymentData.pay_money | moneyFormat }}元</span>
				</p>
				<p><span class="title_3">其中：</span></p>
				<p>
					<span>本次采购帮扶产品金额为：</span>
					<span class="title_2">{{isFupinMoney}}元</span>
				</p>
				<p>
					<span>本次帮扶产品占比为：</span>
					<span class="title_3">{{isFupinMoneyRate}}%</span>
				</p>
				<p>帮扶产品如下：</p>
			</div>
			<div class="isfupin-goods">
				<div class="overflow-wrap">
					<ul :class="{ overflow:  this.isFupinGoodsList > 5 }">
						<li class="item" v-for="(item, index) in isFupinGoodsList" :key="index">
							<div class="img-wrap"><img :src="$img(item.sku_image, { size: 'small' })" @error="imageError(index)" :alt="item.sku_name" /></div>
							<div class="goods-name" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">

<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
								{{ item.sku_name }}
							</div>
							<div class="operation">
								<p>￥{{ item.discount_price }}x{{ item.num }}</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div style="text-align: center;">
				<el-button type="primary"  @click="confirmPayment">确认支付</el-button>
				<el-button type="default"  @click="closeDialogconfirmPayment">取消</el-button>
			</div>
		</el-dialog>

		<!-- 预算单位授权验证 -->
		<el-dialog title="预算单位授权验证" :visible.sync="verifyIsBudget1" width="450px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<el-form :model="isBudgetForm" ref="isBudgetRuleForm1" label-width="70px">
				<el-form-item prop="account" label="账号" >
					<el-input v-model.trim="isBudgetForm.account" placeholder="请输入预算单位账号"></el-input>
				</el-form-item>
				<el-form-item prop="yspassword" label="密码" >
					<el-input type="password" v-model.trim="isBudgetForm.yspassword" autocomplete="off" placeholder="请输入登录密码"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-button type="primary"  @click="accountLogin1" >授权</el-button>
					<el-button type="default"  @click="closeDialog" >取消</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import PicZoom from 'vue-piczoom';
import detail from './payment.js';
export default {
	name: 'payment',
	components: {
		PicZoom
	},
	mixins: [detail]
};
</script>

<style lang="scss">
	.el-input-group__append{width: 100px;padding: 0;}
	.el-dialog__body{padding-top: 0px;}
</style>
<style lang="scss" scoped>
@import './payment.scss';
	.isfupin-box{
		margin: 10px 20px;
		p{
			font-size: 18px;
			line-height: 32px;
			span.title_1{color:blue}
			span.title_2{color:green}
			span.title_3{color:red}
		}
	}
	.isfupin-goods .overflow-wrap{
		margin-bottom: 40px;
		max-height: 300px;
		overflow:auto;
		display: block;
		border-left: 1px solid #dfdfdf;
		border-right: 1px solid #dfdfdf;
		border-bottom: 1px solid #dfdfdf;
	}
	.isfupin-goods .overflow-wrap ul li{
		background-color: #fff;
		display: block;
		font-size: 14px;
		padding: 8px 10px;
		position: relative;
		border-top: 1px solid #dfdfdf;
		overflow: hidden;
	}
	.isfupin-goods .overflow-wrap ul li .img-wrap{
		width: 50px;
		height: 50px;
		margin-right: 5px;
		overflow: hidden;
		float: left;
		text-align: center;
		line-height: 50px;
	}
	.isfupin-goods .overflow-wrap ul li .goods-name{
		width: 100%;
		padding-top: 5px;
	}
	.isfupin-goods .overflow-wrap ul li .operation{
		text-align: left;
	}



	.table-balance{
		font-size: 16px;

		.balance-money{
			color: $base-color;
		}
	}
	.table-but{
		padding-top: 28px;
	}
	.el-dialog__body{
		display: flex !important;
		justify-content: center !important;
		align-content: center !important;

		.order-statistics-show{
			display: flex !important;
			justify-content: center !important;
			align-content: center !important;
		}
	}
	.customBalanceWidth{
		widows: 360px;
		.customBalanceBtn{
			display: flex;
			justify-content: center;
			align-content: center;
		}
	}
	.member-account{
		font-size: 13px;
		line-height: 40px;
		font-weight: 600;
		b{
			color:$base-color;
			font-weight: 600;
			font-size: 18px;
		}
	}
</style>
